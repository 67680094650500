import React, { useEffect } from "react";
import AppWrapper from "../layouts/AppWrapper";

const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AppWrapper>
      <section className="mt-36">
        <h1 className="uppercase tracking-widest mb-5 text-sm text-center font-semibold text-primary md:text-base">
          Privacy Policy/ Term & condition
        </h1>
        <div className="mb-7">
          <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
            Travaapp Terms of Use
          </h3>
          <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
            These Terms and Conditions (Terms) provide for how our service(s)
            may be used whether in person or on our platforms or any other
            affiliated service, platform, or apps – hereafter referred to as
            “service”. No extrinsic evidence, whether oral or written, will be
            incorporated into this Agreement before except to the extent
            permitted by law.
          </p>
          <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
            By signing up or using any of our services, you signify your
            acceptance of our Terms which shall last for the term of Travaapp’s
            existence. If you do not accept the Terms stated here, please do not
            use our service and if you become aware of these terms after
            interacting with our platform, kindly write to us immediately to
            delete your records.
          </p>
          <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
            The use of “Our” or “We” or “Travaapp” below shall refer to Travaapp
            Limited or any other company we incorporate to implement the
            Travaapp . The use of “You” or “user” shall refer to any person,
            entity, or customer that uses or intends to use or procure any of
            the services or offerings on the Travaapp platform.
          </p>
          <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
            These terms are dated the ........... Day of .........., 2022 and
            may be updated from time to time at our sole discretion.
          </p>
        </div>
      </section>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Contractual Relationship
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          You shall ensure that you are lawfully authorized to request our
          services and ensure that all information you provide is true and
          honest and we shall be immediately updated of any change in your
          information. You agree that if you are unsure of the meaning of any
          part of these terms, you will contact us for clarification prior to
          utilizing our service.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Privacy & Confidentiality
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          You assume all responsibility for compliance with all laws and
          regulations.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          We shall request various information from you to provide our services
          in the safest and most trustworthy way. By signing up or utilizing our
          services you unequivocally consent to the use of this information to
          render our services to you.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Where you are using our platform, we may also collect/utilize
          technical information to render our platform and deliver a unique
          experience to you. We shall not harvest or disclose your information
          for unrelated purposes.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          We have the discretion to keep or delete your information from our
          platform. Hence there shall be no liability for deleting your
          information after a reasonable time or where we believe it is no
          longer needed.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          User’s Capacity and Related Matters
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          By using our services, you confirm that you are at least 18 years of
          age or (if below 18 years) that you are acting through the guidance of
          a parent or legal guardian. By using or requesting Travaapp’s
          services, you represent and warrant that:
        </p>
        <p className="  tracking-wide text-sm leading-relaxed ml-5 mb-3 md:w-5/6 lg:w-4/6">
          All information provided by the User is true, complete, and accurate -
          neither fraudulent nor misleading in any respect.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed ml-5 mb-3 md:w-5/6 lg:w-4/6">
          You shall not utilize the platform for any illegal or prohibited
          purpose.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed ml-5 mb-3 md:w-5/6 lg:w-4/6">
          Travaapp reserves the right in its sole discretion, to refuse to
          provide its Services to any user where it deems so.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Terms of Use
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The use of Travaapp constitutes an acceptance of these Terms,
          including any subsequent amendments which may be made to the Travaapp
          terms and conditions from time to time. Your use of our platform shall
          be regarded as your signature and consent.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Travaapp reserves the right to discontinue, suspend or modify the
          Content on its website or app at any time without notice, and may
          block, terminate or suspend yours and any user's access to the its
          website or app at any time for any reason in its sole discretion, even
          if access continues to be allowed to others.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Use of our Services
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Unless otherwise indicated, the Travaapp office address indicated on
          the platform is deemed to be the place of execution and the place of
          departure and the Travaapp office (recipient's address) for collection
          provided is the place of destination.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          We always make our best efforts to provide reasonable and affordable
          pricing for the users but we reserve all rights to vary or review the
          prices from time to time to reflect prevailing market conditions and
          rates.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          We grant you a license to sign up and interact on our platform
          provided you do not defragment, sell or copy it (or a part thereof).
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Travaapp reserves the right to cancel any order or transfer and refund
          any amount paid where we, in our sole discretion, determine that
          proceeding with such transaction will contravene our terms or be
          prejudicial to our interest. In the event where a refund is requested
          or is in issue, we reserve the right to credit you within 2 weeks.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Although Travaapp does its best to provide a minimum verification
          mechanism for users, our platform merely links you to others without
          our intervention. Hence, you are entirely responsible for all
          activities that occur under your account and your interaction with any
          user on our platform as we do not employ any user. They are
          independent service providers whom you have voluntarily chosen.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The service may only be used by you with your details and for your
          items for your purpose. You shall not send or deliver items to any
          third party.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          You acknowledge that we are merely providing technical functionality
          and that you are solely responsible for your representations and
          discussions. Do not use our service for anything other than to find
          senders and dispatchers. We have no duty to monitor the details of
          your discussions or messages.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          We reserve the right to introduce and regulate verification, user,
          use, and item categories from time to time.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Upon discovery of an issue or defect in a transaction, item, or
          delivery, kindly contact Travaap Customer Service on
          +234............................................. (phone number) or
          send us an email within 24 hours to support, travaapl@gmail.com. You
          may also visit us at (address) ...................................
          ................................. ......................
          ......................... .......................
          ........................ .................................
          ...................... ............................., Nigeria. Upon
          your complaint, Travaap reserves the right to request for the item for
          inspection pending the resolution of the issue.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          You hereby agree that all disputes shall first be resolved by
          mediation. You further agree that where we are found to be at fault,
          our maximum aggregate liability in any manner whatsoever shall in no
          circumstances exceed N100,000 plus the amount paid by you to us in
          respect of the transaction in question. Where any part of this
          agreement is invalidated, only that (invalidated) part shall be
          affected.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          You agree that all decisions and information you make while using our
          service are your full responsibility. You shall not disclose secret
          personal details and you shall secure same from the reach of third
          parties. If you detect or become aware of any unauthorized activity or
          use of your account or password on Travaap, you shall immediately
          notify Travaap via its customer contact number
          234....................................... or by email:
          travaapl@gmail.com.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          If you wish to send packages or items using Travaap, the following
          shall apply to you:
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You shall make an honest request on our platform disclosing all
          relevant details including the value of the item and you shall allow
          the dispatcher/traveler to inspect the item you wish to deliver.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You undertake not to request for the delivery of confidential
          information or items and if you must deliver such, you hereby
          undertake that they will not be considered confidential in any way
          whatsoever.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You warrant that you are fully aware of the laws of your domicile, the
          law of your residence, and the law of the land or local government
          where you intend/request to deliver an item. You further warrant that
          you shall not deliver any item that will be prohibited or illegal
          under any of the laws stated above.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          In addition to the foregoing paragraphs, you undertake not to deliver
          any of the following items:
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Upon a request on our platform, we shall link you will with a relevant
          messenger/user. However, we shall be under no liability for any delay
          or failure to deliver the products within the estimated timescales.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You agree that our platform(s) does not constitute an offer but merely
          an invitation to join the pool of users who request and/or deliver
          services.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You agree that the messengers shall not be required to provide a
          return copy or acknowledgment copy of service or delivery. A
          notification on the platform alone will suffice.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You shall provide an accurate, reachable, and valid phone number and
          address of the recipient. Where recipient is not reachable or is
          unable to take his delivery 2 days from the nearest travaap office
          address, the transaction shall be deemed completed and Travaap not
          refund any payment for such service. In the foregoing situation, the
          dispatcher will be entitled take the package back to the sender who
          will be requested to pay a return fee being 70% of the original
          dispatch fee.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You will, at your sole cost and expense, defend, indemnify and hold
          Travaap from all claims, demands, suits, damages, costs, claims
          arising from our out reliance on the information provided by you /
          delivery of your goods where it is found to contravene any of the
          terms of this agreement.
        </p>
        <p className=" tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          If you wish to move, deliver or carry packages or items for the
          user(s) on the Travaap Platform, the following shall apply to you:
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You shall ensure that all orders and payments are handled within the
          platform. Payment shall be made by terms as may be stipulated by our
          representatives from time to time.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You hereby undertake to promptly and diligently carry out the
          delivery/services by the platform request and terms. You shall not
          transfer or assign your obligations.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You shall always make yourself available, reachable, and identifiable
          for real-time tracking and updates to both Travaap any user that
          requested your service on the platform.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Upon gaining possession of an item or delivery, you shall ensure you
          reasonably inspect the content and worth to ascertain that it is not
          an illegal item. You hereby undertake to be responsible for all
          liabilities and damages arising from the handling of any item in your
          possession.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You shall act in good faith at all times and further authorize us to
          access your contacts in cases of emergencies.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          You undertake not to leave items or products with unverified
          recipients. You undertake to refund the full value of the goods in the
          event of a loss or default on your part. You hereby grant Travaap
          right to use every legal means available to recover any outstanding
          amounts, including recourse to your bankers.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Consent to receive Communication
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          By signing up or using our services, the User agrees to receive
          digital communications from Travaap.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Acceptable use of Communication
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The User agrees to use the available Communication Services to voice
          any feedback, complaints, or requests to Travaap publishing anything
          related to Travaap to any third party or the public.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          The User agrees that when using the Services of Travaap, the User will
          not:
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Defame, abuse, harass, stalk, threaten, or otherwise violate the legal
          rights of Travaap, its staff, or any other user(s).
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Publish, post, distribute, dispatch, send, deliver or disseminate any
          names, materials, or information that is considered inappropriate,
          illegal, prohibited, defamatory, infringing, obscene, or unlawful.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Advertise, offer to sell, or offer to buy anything as an agent or
          distributor to Travaap.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Restrict or inhibit any other person or user from using the Services
          of Travaap.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Harvest or otherwise collect personally identifiable information about
          others, without consent.
        </p>
        <p className=" ml-5 tracking-wide text-sm leading-relaxed mb-3 md:w-5/6 lg:w-4/6">
          Violate any code of conduct to be formulated by Travaap from time to
          time.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Intellectual Property Rights
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Travaap retains all intellectual property rights over its products and
          services and without limitation: to its; software, business name,
          trademarks, images, text, graphics, illustrations, logos, service
          marks, copyrights, photographs, and all related intellectual property
          rights. Except as otherwise provided in these Terms, the User shall
          not engage in an unpermitted reproduction or utilization of the
          goodwill or intellectual property rights of Travaap. All goodwill
          generated from the use of Travaap’s trademarks is reserved for the use
          of Travaap, exclusively.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The Travaap’s website or app and its Content are protected by the laws
          of the Federal Republic of Nigeria and applicable international
          intellectual property and other laws. Its logo is the registered
          trademark in Nigeria. Travaap does not convey to anyone, through
          allowing access to its website or app, any ownership rights in the
          travaap website/app or in any Content appearing on or made available
          through the travaap website or app. Customer may not copy, modify,
          translate, transmit, distribute, adapt, reproduce, decompile, reverse
          engineer or disassemble any part of the travaap website/app or its
          Content thereof.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Copyright Infringement
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          If the User believes that any material on the Site infringes upon any
          copyright owned and/or controlled by the User, the User may send a
          notice of alleged infringement to the contact information provided in
          these terms and conditions.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Disclaimer of Responsibility and Liability
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          THE USER AGREES THAT; IN NO EVENT WILL TRAVAAPP /OR ITS AFFILIATES BE
          LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR
          CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS,
          REGARDLESS OF LEGAL THEORY. THE USER FURTHER WARRANTS THAT UNDER NO
          CIRCUMSTANCE SHALL THE LIABILITY OF TRAVAAP EXCEED THE VALUE LIMIT OF
          N100,000 PLUS THE AMOUNT PAID BY THE USER NOTWITHSTANDING ANY CLAIM.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Release and Indemnity
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The User personally and on behalf of the User’s heirs, executors,
          agents, representatives, and assigns, fully release, defends,
          discharges, and holds Travaap, and/or its affiliates, harmless - to
          the extent permitted by law - from any losses, damages, expenses,
          claims, and actions of any kind arising out of or relating to the use
          of Travaap’s Services.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Dispute Resolution
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Disputes by the User should be first handled using the Travaap
          resolution center. User concerns are first addressed by calling our
          customer care line on +234..................... or sending an email to
          travaapl@gmail.com or visiting our address at
          .......................... ........................
          ........................ ..................Nigeria
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          However, if Travaap is not able to resolve a dispute with the User
          within two weeks after attempts to do so informally, then as a
          condition to using of the services, the User agrees with Travaap that
          such disputes, claims, rights arising thereof shall be resolved
          through binding mediation before a sole mediator to be appointed by
          both parties. The proceedings shall be held in lagos, Nigeria, or any
          other location as to which the Travaap and the User may agree in
          writing.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The cost of dispute resolution shall be borne by the party instituting
          the same. Any party seeking mediation must first send a written notice
          of dispute. Any notice to Travaap should be addressed to the email
          address stated earlier but where it involves the commencement of
          dispute resolution, it should be addressed to Travaap's physical
          address stated earlier.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Any notice to be sent by Travaap to the User may be sent to the User’s
          email address or phone number provided during the sign-up process.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          Any cause of action concerning the service or related to Travaap be
          instituted within one year after the claim or cause of action has
          risen or been barred.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Applicable Law
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          These Terms shall and will be governed by the Laws of the Federal
          Republic of Nigeria which shall for all intent and purposes be the
          governing law of this Terms.
        </p>
      </div>
      <div className="mb-7">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Entire Agreement
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          These Terms constitute the entire agreement between the User and
          Travaap its representatives concerning its subject matter and
          supersede and replace any other prior or contemporaneous agreements,
          or terms and conditions applicable to the subject matter of this
          Terms. Provided that these Terms shall in no way be construed as
          creating third-party beneficiary rights.
        </p>
      </div>
      <div className="mb-16">
        <h3 className="text-lg font-medium tracking-wide text-primary mb-3 ">
          Waiver, Severability, and Assignment
        </h3>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The failure of Travaap enforce any provision in this Terms is not and
          shall not be construed as a waiver of its right to do so at a later
          time.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          If any provision of these Terms is found unenforceable, the remaining
          provisions of the Terms will remain in full effect while the
          infringing term shall be amended to reflect the intent of Travaap
          nearly as possible.
        </p>
        <p className="  tracking-wide text-sm leading-relaxed mb-5 md:w-5/6 lg:w-4/6">
          The User may not assign any rights under these Terms, and any such
          attempt to do so shall be void and of no effect. Provided that Travaap
          its privies shall reserve the right to assign its rights to any of its
          affiliates or subsidiaries, or any successor in the interest of any
          business associated with the Services
        </p>
      </div>
    </AppWrapper>
  );
};

export default PrivacyPolicy;
